import { reactive, defineComponent, onMounted, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import routePath from "@/commons/RoutePath";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import DIContainer from "@/core/DIContainer";
import CommonService from "@/services/CommonService";
import Navbar from "@/presentation/components/navigation/Navigation.vue";
import JoinTeamPopup from "@/presentation/components/modals/join-team-popup/JoinTeamPopup.vue";
import RejectTeamPopup from "@/presentation/components/modals/reject-team-popup/RejectTeamPopup.vue";
import TransformUtil from "@/commons/TransformUtil";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import { useStore } from "vuex";
export default defineComponent({
    name: "TeamDetail",
    components: {
        Preloaders: Preloaders,
        Navbar: Navbar,
        JoinTeamPopup: JoinTeamPopup,
        RejectTeamPopup: RejectTeamPopup,
        Alert: Alert
    },
    setup: function () {
        var router = useRouter();
        var route = useRoute();
        var store = useStore();
        var state = reactive({
            teamInfo: {},
            preloadMain: true,
            account_id: null,
            loadingAccept: false,
            loadingReject: false,
            showPopupInvited: false,
            titlePopup: "",
            request_token: route.query.token,
            showPopupReject: false,
            titleReject: "",
            contentReject: "",
            okButtonReject: "",
            showPopupAlert: false,
            titleAlert: "",
            checkToken: true,
            confirmType: ""
        });
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        function goback() {
            router.push(routePath.TEAMS);
        }
        function gotoUserAchievement(userId) {
            router.push("/user/profile/" + userId + "/achievement");
        }
        function gotoUserInfo(userId) {
            router.push("/user/profile/" + userId + "/info");
        }
        function proccessSkill(skills) {
            var categories = [];
            if (!skills) {
                return [];
            }
            skills = skills.sort(function (a, b) { return a.category_id - b.category_id; });
            var cache_id = null;
            skills.forEach(function (skill) {
                if (skill.category_id != cache_id) {
                    cache_id = skill.category_id;
                    categories.push({
                        category_name: skill.category_name,
                        category_id: skill.category_id,
                        skills: [skill]
                    });
                }
                else {
                    categories[categories.length - 1].skills.push(skill);
                }
            });
            return categories;
        }
        function acceptChangeLeader() {
            state.titleReject = "リーダー変更の確認";
            state.contentReject = "リーダー変更依頼を承認します。<br/>よろしければ「承認する」ボタンを押してください。";
            state.okButtonReject = "承認する";
            state.confirmType = "accept";
            state.showPopupReject = true;
        }
        function rejectChangeLeader() {
            state.titleReject = "リーダー変更の確認";
            state.contentReject = "リーダー変更依頼を拒否する<br/>よろしければ「辞退する」ボタンを押してください。";
            state.okButtonReject = "拒否する";
            state.confirmType = "reject";
            state.showPopupReject = true;
        }
        function acceptConfirm() {
            if (state.confirmType == "reject") {
                state.titleAlert = "";
                state.loadingReject = true;
                return teamInteractor.rejectChangeLeader(state.request_token).then(function (result) {
                    store.commit("setInviteTeamAlert", {
                        show: true,
                        title: "リーダー変更の確認",
                        content: "リーダー変更を拒否しました"
                    });
                    router.push(routePath.USER_PROFILE + '/' + state.account_id + '/team');
                }).catch(function (error) {
                    state.loadingReject = false;
                    if (error.message) {
                        state.showPopupReject = false;
                        state.showPopupAlert = true;
                        state.titleAlert = error.message;
                    }
                });
            }
            else {
                state.titleAlert = "";
                state.loadingAccept = true;
                return teamInteractor.acceptChangeLeader(state.request_token).then(function (result) {
                    store.commit("setInviteTeamAlert", {
                        show: true,
                        title: "リーダー変更の確認",
                        content: "リーダー変更を承認しました"
                    });
                    router.push(routePath.USER_PROFILE + '/' + state.account_id + '/team');
                }).catch(function (error) {
                    state.loadingAccept = false;
                    if (error.message) {
                        state.showPopupAlert = true;
                        state.titleAlert = error.message;
                    }
                });
            }
        }
        function closePopupAlert() {
            state.showPopupAlert = false;
        }
        function closePopupConfirm() {
            state.showPopupReject = false;
            state.titleReject = "";
            state.contentReject = "";
        }
        watchEffect(function () {
            if (store.state.userMenuInfo.nickname) {
                // state.user_nickname = store.state.userMenuInfo.nickname;
                state.titleReject = "リーダー変更拒否の確認";
                state.contentReject = "チームリーダーの変更を拒否します。<br/>よろしければ「拒否する」ボタンを押してください。";
                state.okButtonReject = "拒否する";
            }
        });
        onMounted(function () {
            var user = CommonService.getCookie("user");
            var user_infor = user ? JSON.parse(decodeURIComponent(user)) : null;
            state.account_id = user_infor && user_infor['account_id'] ? user_infor['account_id'] : null;
            teamInteractor.checkTokenRequested(state.request_token).then(function (result) {
                var _a;
                state.checkToken = false;
                if (!result.data) {
                    return router.push(routePath.TEAM_INVITE_EXPIRED);
                }
                var team_id = (_a = result.data.team_id) !== null && _a !== void 0 ? _a : null;
                return teamInteractor.getTeamById(team_id).then(function (result) {
                    state.preloadMain = false;
                    if (!result.data) {
                        return;
                    }
                    state.teamInfo = result.data;
                    if (state.teamInfo && state.teamInfo.members) {
                        state.teamInfo.members.map(function (member) {
                            member.user_rating_ave = member.user_rating_ave ? TransformUtil.pointRound(member.user_rating_ave) : 0;
                            member.user_rating_count = TransformUtil.formatNumber(member.user_rating_count);
                        });
                    }
                }).catch(function (error) {
                    state.preloadMain = false;
                    router.push(routePath.PAGE_404);
                });
            }).catch(function (error) {
                state.checkToken = false;
                if (error.code == 400) {
                    router.push(routePath.TEAM_INVITE_EXPIRED);
                }
                else {
                    router.push(routePath.PAGE_404);
                }
            });
        });
        return {
            state: state,
            routePath: routePath,
            goback: goback,
            gotoUserAchievement: gotoUserAchievement,
            gotoUserInfo: gotoUserInfo,
            acceptChangeLeader: acceptChangeLeader,
            rejectChangeLeader: rejectChangeLeader,
            closePopupAlert: closePopupAlert,
            acceptConfirm: acceptConfirm,
            closePopupConfirm: closePopupConfirm
        };
    }
});
