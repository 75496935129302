<template>
  <div class="team_leader_change">
    <Navbar />
    <div class="container">
      <div class="main-area">
        <div class="team_leader_change__content" v-if="!state.preloadMain">
          <h3>{{state.teamInfo.name}}からのリーダー変更依頼</h3>
          <div class="alert_chage_leader">
            <p>リーダー権限で行える機能は以下の通りです。</p>
            <ul class="alert_chage_leader__authority">
              <li>チーム情報の変更</li>
              <li>メンバーの招待及び脱退</li>
              <li>チームの解散・削除</li>
              <li>企業担当者とのメッセージ機能(※)</li>
            </ul>
            <p class="alert_messege">※ 前任のリーダーと企業担当者間のメッセージは引き継げません。
              必要に応じて、前任のリーダーから共有してもらうようお願いいたします。</p>
          </div>
          <div class="btn_group">
            <button
              :disabled="state.loadingAccept || state.loadingReject"
              class="btn-md btn-light-gray"
              type="button"
              @click="rejectChangeLeader"
            >
              辞退する
            </button>
            <button
              :disabled="state.loadingAccept || state.loadingReject"
              class="btn-md btn-blue shadow"
              type="button"
              @click="acceptChangeLeader"
            >
              リーダーになる
            </button>
          </div>
        </div>
        <TeamDetailPreloader v-else />
      </div>
    </div>
    <Alert v-if="state.showPopupAlert" @close="closePopupAlert" :content="state.titleAlert" />
    <RejectTeamPopup
      v-if="state.showPopupReject"
      @close="closePopupConfirm"
      :title="state.titleReject"
      :content="state.contentReject"
      :okButtonText="state.okButtonReject"
      :type="state.confirmType"
      :loading="state.confirmType == 'reject' ? state.loadingReject : state.loadingAccept"
      @accept="acceptConfirm"
    />
    <div class="white_screen" v-if="state.checkToken"></div>
  </div>
</template>
<script lang="ts" src="@/presentation/views/user/team/change-leader-requested/ChangeLeaderRequested.ts">
</script>
<style lang="scss" src="@/presentation/views/user/team/change-leader-requested/ChangeLeaderRequested.scss" scoped>
</style>
